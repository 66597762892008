import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
}

export type AiSheetDatasInput = {
  cycle?: InputMaybe<Scalars['Int']>
  lang: SheetLangType
  search: Scalars['String']
  subject?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['Int']>
}

export type Admin = {
  __typename?: 'Admin'
  email: Scalars['String']
  id: Scalars['Int']
}

export type AuthUser = {
  __typename?: 'AuthUser'
  currency: Currency
  generated: Scalars['Int']
  initials: Scalars['String']
  photo?: Maybe<Scalars['String']>
  premium: Scalars['Boolean']
  token: Scalars['String']
}

export type Blog = {
  __typename?: 'Blog'
  date: Scalars['String']
  header: Scalars['String']
  id: Scalars['Int']
  state: Scalars['String']
  summary: Scalars['String']
  title: Scalars['String']
  value: Scalars['String']
  writer: Scalars['String']
}

export enum CreateFrom {
  Mcq = 'MCQ',
  User = 'USER',
}

export type CreateSheet = {
  __typename?: 'CreateSheet'
  id?: Maybe<Scalars['Int']>
  saveToken?: Maybe<Scalars['ID']>
}

export enum Currency {
  Eur = 'EUR',
  Usd = 'USD',
}

export type DashboadType = {
  __typename?: 'DashboadType'
  downloads: Scalars['Int']
  drafts: Scalars['Int']
  mcq: Scalars['BigInt']
  perDateSheet: PerDate
  perDateUser: PerDate
  sheets: Scalars['Int']
  todaySheets: Scalars['Int']
  todayUsers: Scalars['Int']
  users: Scalars['Int']
  views: Scalars['Int']
}

export type DataInformations = {
  __typename?: 'DataInformations'
  definitions: Scalars['Int']
  sheets: Scalars['Int']
  users: Scalars['Int']
}

export type GeneratedSheet = {
  __typename?: 'GeneratedSheet'
  accessibility: SheetAccessibilityType
  cycle?: Maybe<Scalars['Int']>
  date: Scalars['String']
  id: Scalars['Int']
  isValid: Scalars['Boolean']
  study?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  title: Scalars['String']
  token?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
}

export type GeneratedSheetDetails = {
  __typename?: 'GeneratedSheetDetails'
  cycle?: Maybe<Scalars['Int']>
  hasAccess: Scalars['Boolean']
  study?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  title: Scalars['String']
  type: Scalars['Int']
  value: Scalars['String']
  year?: Maybe<Scalars['Int']>
}

export type LoginAdmin = {
  __typename?: 'LoginAdmin'
  level: Scalars['Int']
  token: Scalars['String']
}

export type McqHistory = {
  __typename?: 'MCQHistory'
  correct: Scalars['Int']
  createFrom: CreateFrom
  date: Scalars['String']
  sheetId: Scalars['Int']
  sheetTitle: Scalars['String']
  total: Scalars['Int']
  value: Scalars['String']
}

export type Mct = {
  __typename?: 'MCT'
  answer: Scalars['Int']
  options: Array<MctOption>
  question: Scalars['String']
  sheet: Scalars['Int']
}

export type MctInput = {
  fromText?: InputMaybe<Scalars['String']>
  questions: Array<MctQuestionInput>
  sheet?: InputMaybe<Scalars['Int']>
}

export type MctOption = {
  __typename?: 'MCTOption'
  index: Scalars['Int']
  text: Scalars['String']
}

export type MctQuestionInput = {
  answer: Scalars['Int']
  options: Array<MctQuestionOptionInput>
  question: Scalars['String']
}

export type MctQuestionOptionInput = {
  index?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  AIDefinition: Scalars['String']
  AISheet: Scalars['String']
  Advise: Scalars['Boolean']
  BlogAdmin: Scalars['String']
  Checkout: Scalars['String']
  CreateMCT: Array<Mct>
  CreateOrUpdateSheet: CreateSheet
  Delete: Scalars['Boolean']
  DeleteUser: Scalars['Boolean']
  Login: AuthUser
  LoginAdmin?: Maybe<LoginAdmin>
  PaymentIntent: PaymentIntent
  Register: AuthUser
  ResetPassword: Scalars['Boolean']
  SaveMCQ: Scalars['Boolean']
  SendEmail: Scalars['Boolean']
  SheetManagement: Scalars['Boolean']
  Subscription: Scalars['String']
  UpdateAccessibility: Scalars['Boolean']
  UpdatePassword: Scalars['Boolean']
  UpdateUserInformations: Scalars['Boolean']
  Upload: Scalars['String']
  ValidateEmail: Scalars['Boolean']
}

export type MutationAiDefinitionArgs = {
  lang: SheetLangType
  search: Scalars['String']
}

export type MutationAiSheetArgs = {
  datas: AiSheetDatasInput
}

export type MutationAdviseArgs = {
  content: Scalars['String']
  rating: Scalars['Int']
}

export type MutationBlogAdminArgs = {
  header?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  state?: InputMaybe<Scalars['Int']>
  summary?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type MutationCheckoutArgs = {
  id: Scalars['ID']
}

export type MutationCreateMctArgs = {
  mct: MctInput
}

export type MutationCreateOrUpdateSheetArgs = {
  data: SheetDataInput
}

export type MutationDeleteArgs = {
  draft?: InputMaybe<Scalars['Boolean']>
  sheet: Scalars['Int']
}

export type MutationDeleteUserArgs = {
  user?: InputMaybe<Scalars['String']>
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationLoginAdminArgs = {
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationPaymentIntentArgs = {
  currency?: InputMaybe<Currency>
}

export type MutationRegisterArgs = {
  data: RegisterInput
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']
}

export type MutationSaveMcqArgs = {
  mcq: SaveMcqInput
}

export type MutationSendEmailArgs = {
  data: SendEmailInput
}

export type MutationSheetManagementArgs = {
  sheet: Scalars['Int']
  type: SheetManagementType
}

export type MutationSubscriptionArgs = {
  price?: InputMaybe<SubscriptionType>
}

export type MutationUpdateAccessibilityArgs = {
  accessibility: SheetAccessibilityType
  sheet: Scalars['Int']
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationUpdateUserInformationsArgs = {
  data: UserInformationsInput
}

export type MutationUploadArgs = {
  data: Scalars['String']
  type: UploadType
}

export type MutationValidateEmailArgs = {
  code: Scalars['String']
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent'
  id: Scalars['String']
  secret: Scalars['String']
}

export type PerDate = {
  __typename?: 'PerDate'
  x: Array<Scalars['String']>
  y: Array<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  AICorrection: Array<Scalars['String']>
  AdminDashboard: DashboadType
  AdminList: Array<Admin>
  AdminSheets: Search
  AdminUsers: UserList
  Blogs: Array<Blog>
  GeneratedSheet: GeneratedSheetDetails
  GlobalInformations: DataInformations
  MCQHistory: Array<McqHistory>
  Mct: Array<Mct>
  PortalSession: Scalars['String']
  PremiumStatus: Scalars['Boolean']
  Resume: SheetResume
  Search: Search
  SheetInformation: SheetInformation
  Sheets: UserSheets
  UserInformations: UserInformations
}

export type QueryAiCorrectionArgs = {
  input: Array<Scalars['String']>
  lang: SheetLangType
}

export type QueryAdminDashboardArgs = {
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export type QueryAdminSheetsArgs = {
  page?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  toValidate?: InputMaybe<Scalars['Boolean']>
}

export type QueryAdminUsersArgs = {
  email?: InputMaybe<Scalars['String']>
  firstname?: InputMaybe<Scalars['String']>
  lastname?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  token?: InputMaybe<Scalars['String']>
}

export type QueryBlogsArgs = {
  id?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}

export type QueryGeneratedSheetArgs = {
  sheet: Scalars['Int']
}

export type QueryMctArgs = {
  sheet: Scalars['Int']
}

export type QueryResumeArgs = {
  sheet: Scalars['String']
}

export type QuerySearchArgs = {
  cycle?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  subject?: InputMaybe<Scalars['String']>
}

export type QuerySheetInformationArgs = {
  token: Scalars['ID']
}

export type RegisterInput = {
  acceptEmail?: InputMaybe<Scalars['Boolean']>
  email: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  nationality: Scalars['String']
  password: Scalars['String']
}

export type SaveMcqInput = {
  correct: Scalars['Int']
  sheetId: Scalars['Int']
  total: Scalars['Int']
  value: Scalars['String']
}

export type SavedSheet = {
  __typename?: 'SavedSheet'
  date: Scalars['String']
  id: Scalars['Int']
  title: Scalars['String']
  token: Scalars['String']
}

export type Search = {
  __typename?: 'Search'
  count: Scalars['Int']
  sheets: Array<Sheet>
}

export type SendEmailInput = {
  all: Scalars['Boolean']
  content: Scalars['String']
  emails: Array<Scalars['String']>
  subject: Scalars['String']
}

export type Sheet = {
  __typename?: 'Sheet'
  createFrom: CreateFrom
  cycle?: Maybe<Scalars['Int']>
  date: Scalars['String']
  id: Scalars['Int']
  isValid: Scalars['Boolean']
  mcq: Scalars['BigInt']
  review: Scalars['Float']
  study?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  title: Scalars['String']
  type?: Maybe<Scalars['Int']>
  user: Scalars['String']
  userToken: Scalars['String']
  withAI: Scalars['Boolean']
  year?: Maybe<Scalars['Int']>
}

export enum SheetAccessibilityType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum SheetActionType {
  Create = 'CREATE',
  Save = 'SAVE',
}

export type SheetDataInput = {
  action: SheetActionType
  datas: SheetDatasInput
  editor?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  img: Scalars['String']
  lang?: InputMaybe<SheetLangType>
  payment?: InputMaybe<Scalars['ID']>
  sheet?: InputMaybe<Scalars['ID']>
  withAi?: InputMaybe<Scalars['Boolean']>
}

export type SheetDatasInput = {
  accessibility: SheetAccessibilityType
  cycle?: InputMaybe<Scalars['Int']>
  font?: InputMaybe<Scalars['String']>
  fontSize?: InputMaybe<Scalars['Float']>
  primaryColor?: InputMaybe<Scalars['String']>
  speciality?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  study?: InputMaybe<Scalars['String']>
  subject?: InputMaybe<Scalars['String']>
  template?: InputMaybe<Scalars['String']>
  title: Scalars['String']
  withDefintions?: InputMaybe<Scalars['Boolean']>
  year?: InputMaybe<Scalars['Int']>
}

export type SheetDefinitionObject = {
  __typename?: 'SheetDefinitionObject'
  type: Scalars['Int']
  value: SheetDefinitionValue
}

export type SheetDefinitionValue = {
  __typename?: 'SheetDefinitionValue'
  content?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type SheetInformation = {
  __typename?: 'SheetInformation'
  editor: Scalars['String']
  token: Scalars['ID']
}

export enum SheetLangType {
  En = 'EN',
  Fr = 'FR',
}

export enum SheetManagementType {
  Delete = 'DELETE',
  ToogleState = 'TOOGLE_STATE',
}

export type SheetObject = {
  __typename?: 'SheetObject'
  type: Scalars['Int']
  value: Scalars['String']
}

export type SheetObjectUnion = SheetDefinitionObject | SheetObject

export type SheetResume = {
  __typename?: 'SheetResume'
  datas: Scalars['String']
  editor: Scalars['String']
  sheet?: Maybe<Scalars['Int']>
}

export enum SheetType {
  Generated = 'GENERATED',
  Pdf = 'PDF',
}

export enum SubscriptionType {
  Mensual = 'MENSUAL',
  Trimestrial = 'TRIMESTRIAL',
}

export type UpdatePasswordInput = {
  newPassword: Scalars['String']
  token: Scalars['String']
  type: UpdatePasswordType
}

export enum UpdatePasswordType {
  Reset = 'RESET',
  Update = 'UPDATE',
}

export enum UploadType {
  Upload = 'UPLOAD',
  User = 'USER',
}

export type User = {
  __typename?: 'User'
  address?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  drafts: Array<SavedSheet>
  email?: Maybe<Scalars['String']>
  firstname?: Maybe<Scalars['String']>
  id: Scalars['Int']
  lastname?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  premium: Scalars['Boolean']
  registration: Scalars['String']
  sheets: Array<GeneratedSheet>
  token: Scalars['String']
  zip?: Maybe<Scalars['String']>
}

export type UserInformations = {
  __typename?: 'UserInformations'
  acceptEmail?: Maybe<Scalars['Boolean']>
  address?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstname?: Maybe<Scalars['String']>
  formation?: Maybe<Scalars['String']>
  generate?: Maybe<Scalars['Int']>
  lastname?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  pseudo?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type UserInformationsInput = {
  accept_email?: InputMaybe<Scalars['Boolean']>
  address?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstname?: InputMaybe<Scalars['String']>
  formation?: InputMaybe<Scalars['String']>
  lastname?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  photo?: InputMaybe<Scalars['String']>
  pseudo?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type UserList = {
  __typename?: 'UserList'
  count: Scalars['Int']
  users: Array<User>
}

export type UserSheets = {
  __typename?: 'UserSheets'
  generatedSheets: Array<GeneratedSheet>
  savedSheets: Array<SavedSheet>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes = {
  SheetObjectUnion: SheetDefinitionObject | SheetObject
}

/** Mapping of union parent types */
export type ResolversUnionParentTypes = {
  SheetObjectUnion: SheetDefinitionObject | SheetObject
}

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AISheetDatasInput: AiSheetDatasInput
  Admin: ResolverTypeWrapper<Admin>
  AuthUser: ResolverTypeWrapper<AuthUser>
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>
  Blog: ResolverTypeWrapper<Blog>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  CreateFrom: CreateFrom
  CreateSheet: ResolverTypeWrapper<CreateSheet>
  Currency: Currency
  DashboadType: ResolverTypeWrapper<DashboadType>
  DataInformations: ResolverTypeWrapper<DataInformations>
  Float: ResolverTypeWrapper<Scalars['Float']>
  GeneratedSheet: ResolverTypeWrapper<GeneratedSheet>
  GeneratedSheetDetails: ResolverTypeWrapper<GeneratedSheetDetails>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  LoginAdmin: ResolverTypeWrapper<LoginAdmin>
  MCQHistory: ResolverTypeWrapper<McqHistory>
  MCT: ResolverTypeWrapper<Mct>
  MCTInput: MctInput
  MCTOption: ResolverTypeWrapper<MctOption>
  MCTQuestionInput: MctQuestionInput
  MCTQuestionOptionInput: MctQuestionOptionInput
  Mutation: ResolverTypeWrapper<{}>
  PaymentIntent: ResolverTypeWrapper<PaymentIntent>
  PerDate: ResolverTypeWrapper<PerDate>
  Query: ResolverTypeWrapper<{}>
  RegisterInput: RegisterInput
  SaveMCQInput: SaveMcqInput
  SavedSheet: ResolverTypeWrapper<SavedSheet>
  Search: ResolverTypeWrapper<Search>
  SendEmailInput: SendEmailInput
  Sheet: ResolverTypeWrapper<Sheet>
  SheetAccessibilityType: SheetAccessibilityType
  SheetActionType: SheetActionType
  SheetDataInput: SheetDataInput
  SheetDatasInput: SheetDatasInput
  SheetDefinitionObject: ResolverTypeWrapper<SheetDefinitionObject>
  SheetDefinitionValue: ResolverTypeWrapper<SheetDefinitionValue>
  SheetInformation: ResolverTypeWrapper<SheetInformation>
  SheetLangType: SheetLangType
  SheetManagementType: SheetManagementType
  SheetObject: ResolverTypeWrapper<SheetObject>
  SheetObjectUnion: ResolverTypeWrapper<ResolversUnionTypes['SheetObjectUnion']>
  SheetResume: ResolverTypeWrapper<SheetResume>
  SheetType: SheetType
  String: ResolverTypeWrapper<Scalars['String']>
  SubscriptionType: SubscriptionType
  UpdatePasswordInput: UpdatePasswordInput
  UpdatePasswordType: UpdatePasswordType
  UploadType: UploadType
  User: ResolverTypeWrapper<User>
  UserInformations: ResolverTypeWrapper<UserInformations>
  UserInformationsInput: UserInformationsInput
  UserList: ResolverTypeWrapper<UserList>
  UserSheets: ResolverTypeWrapper<UserSheets>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AISheetDatasInput: AiSheetDatasInput
  Admin: Admin
  AuthUser: AuthUser
  BigInt: Scalars['BigInt']
  Blog: Blog
  Boolean: Scalars['Boolean']
  CreateSheet: CreateSheet
  DashboadType: DashboadType
  DataInformations: DataInformations
  Float: Scalars['Float']
  GeneratedSheet: GeneratedSheet
  GeneratedSheetDetails: GeneratedSheetDetails
  ID: Scalars['ID']
  Int: Scalars['Int']
  LoginAdmin: LoginAdmin
  MCQHistory: McqHistory
  MCT: Mct
  MCTInput: MctInput
  MCTOption: MctOption
  MCTQuestionInput: MctQuestionInput
  MCTQuestionOptionInput: MctQuestionOptionInput
  Mutation: {}
  PaymentIntent: PaymentIntent
  PerDate: PerDate
  Query: {}
  RegisterInput: RegisterInput
  SaveMCQInput: SaveMcqInput
  SavedSheet: SavedSheet
  Search: Search
  SendEmailInput: SendEmailInput
  Sheet: Sheet
  SheetDataInput: SheetDataInput
  SheetDatasInput: SheetDatasInput
  SheetDefinitionObject: SheetDefinitionObject
  SheetDefinitionValue: SheetDefinitionValue
  SheetInformation: SheetInformation
  SheetObject: SheetObject
  SheetObjectUnion: ResolversUnionParentTypes['SheetObjectUnion']
  SheetResume: SheetResume
  String: Scalars['String']
  UpdatePasswordInput: UpdatePasswordInput
  User: User
  UserInformations: UserInformations
  UserInformationsInput: UserInformationsInput
  UserList: UserList
  UserSheets: UserSheets
}

export type AdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthUser'] = ResolversParentTypes['AuthUser'],
> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  generated?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  initials?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  premium?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface BigIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt'
}

export type BlogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Blog'] = ResolversParentTypes['Blog'],
> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  header?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  writer?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateSheetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateSheet'] = ResolversParentTypes['CreateSheet'],
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  saveToken?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboadTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DashboadType'] = ResolversParentTypes['DashboadType'],
> = {
  downloads?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  drafts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  mcq?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  perDateSheet?: Resolver<ResolversTypes['PerDate'], ParentType, ContextType>
  perDateUser?: Resolver<ResolversTypes['PerDate'], ParentType, ContextType>
  sheets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  todaySheets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  todayUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DataInformationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataInformations'] = ResolversParentTypes['DataInformations'],
> = {
  definitions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sheets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  users?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GeneratedSheetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneratedSheet'] = ResolversParentTypes['GeneratedSheet'],
> = {
  accessibility?: Resolver<
    ResolversTypes['SheetAccessibilityType'],
    ParentType,
    ContextType
  >
  cycle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  study?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GeneratedSheetDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneratedSheetDetails'] = ResolversParentTypes['GeneratedSheetDetails'],
> = {
  cycle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hasAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  study?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoginAdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoginAdmin'] = ResolversParentTypes['LoginAdmin'],
> = {
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type McqHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MCQHistory'] = ResolversParentTypes['MCQHistory'],
> = {
  correct?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  createFrom?: Resolver<ResolversTypes['CreateFrom'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sheetId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sheetTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MctResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MCT'] = ResolversParentTypes['MCT'],
> = {
  answer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  options?: Resolver<
    Array<ResolversTypes['MCTOption']>,
    ParentType,
    ContextType
  >
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sheet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MctOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MCTOption'] = ResolversParentTypes['MCTOption'],
> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  AIDefinition?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationAiDefinitionArgs, 'lang' | 'search'>
  >
  AISheet?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationAiSheetArgs, 'datas'>
  >
  Advise?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationAdviseArgs, 'content' | 'rating'>
  >
  BlogAdmin?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    Partial<MutationBlogAdminArgs>
  >
  Checkout?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationCheckoutArgs, 'id'>
  >
  CreateMCT?: Resolver<
    Array<ResolversTypes['MCT']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMctArgs, 'mct'>
  >
  CreateOrUpdateSheet?: Resolver<
    ResolversTypes['CreateSheet'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrUpdateSheetArgs, 'data'>
  >
  Delete?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteArgs, 'sheet'>
  >
  DeleteUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    Partial<MutationDeleteUserArgs>
  >
  Login?: Resolver<
    ResolversTypes['AuthUser'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginArgs, 'email' | 'password'>
  >
  LoginAdmin?: Resolver<
    Maybe<ResolversTypes['LoginAdmin']>,
    ParentType,
    ContextType,
    RequireFields<MutationLoginAdminArgs, 'password' | 'username'>
  >
  PaymentIntent?: Resolver<
    ResolversTypes['PaymentIntent'],
    ParentType,
    ContextType,
    Partial<MutationPaymentIntentArgs>
  >
  Register?: Resolver<
    ResolversTypes['AuthUser'],
    ParentType,
    ContextType,
    RequireFields<MutationRegisterArgs, 'data'>
  >
  ResetPassword?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationResetPasswordArgs, 'email'>
  >
  SaveMCQ?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSaveMcqArgs, 'mcq'>
  >
  SendEmail?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSendEmailArgs, 'data'>
  >
  SheetManagement?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSheetManagementArgs, 'sheet' | 'type'>
  >
  Subscription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    Partial<MutationSubscriptionArgs>
  >
  UpdateAccessibility?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAccessibilityArgs, 'accessibility' | 'sheet'>
  >
  UpdatePassword?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePasswordArgs, 'input'>
  >
  UpdateUserInformations?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserInformationsArgs, 'data'>
  >
  Upload?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationUploadArgs, 'data' | 'type'>
  >
  ValidateEmail?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationValidateEmailArgs, 'code'>
  >
}

export type PaymentIntentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentIntent'] = ResolversParentTypes['PaymentIntent'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PerDateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PerDate'] = ResolversParentTypes['PerDate'],
> = {
  x?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  y?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  AICorrection?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryAiCorrectionArgs, 'input' | 'lang'>
  >
  AdminDashboard?: Resolver<
    ResolversTypes['DashboadType'],
    ParentType,
    ContextType,
    RequireFields<QueryAdminDashboardArgs, 'endDate' | 'startDate'>
  >
  AdminList?: Resolver<Array<ResolversTypes['Admin']>, ParentType, ContextType>
  AdminSheets?: Resolver<
    ResolversTypes['Search'],
    ParentType,
    ContextType,
    Partial<QueryAdminSheetsArgs>
  >
  AdminUsers?: Resolver<
    ResolversTypes['UserList'],
    ParentType,
    ContextType,
    Partial<QueryAdminUsersArgs>
  >
  Blogs?: Resolver<
    Array<ResolversTypes['Blog']>,
    ParentType,
    ContextType,
    Partial<QueryBlogsArgs>
  >
  GeneratedSheet?: Resolver<
    ResolversTypes['GeneratedSheetDetails'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneratedSheetArgs, 'sheet'>
  >
  GlobalInformations?: Resolver<
    ResolversTypes['DataInformations'],
    ParentType,
    ContextType
  >
  MCQHistory?: Resolver<
    Array<ResolversTypes['MCQHistory']>,
    ParentType,
    ContextType
  >
  Mct?: Resolver<
    Array<ResolversTypes['MCT']>,
    ParentType,
    ContextType,
    RequireFields<QueryMctArgs, 'sheet'>
  >
  PortalSession?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  PremiumStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  Resume?: Resolver<
    ResolversTypes['SheetResume'],
    ParentType,
    ContextType,
    RequireFields<QueryResumeArgs, 'sheet'>
  >
  Search?: Resolver<
    ResolversTypes['Search'],
    ParentType,
    ContextType,
    Partial<QuerySearchArgs>
  >
  SheetInformation?: Resolver<
    ResolversTypes['SheetInformation'],
    ParentType,
    ContextType,
    RequireFields<QuerySheetInformationArgs, 'token'>
  >
  Sheets?: Resolver<ResolversTypes['UserSheets'], ParentType, ContextType>
  UserInformations?: Resolver<
    ResolversTypes['UserInformations'],
    ParentType,
    ContextType
  >
}

export type SavedSheetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedSheet'] = ResolversParentTypes['SavedSheet'],
> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Search'] = ResolversParentTypes['Search'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sheets?: Resolver<Array<ResolversTypes['Sheet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Sheet'] = ResolversParentTypes['Sheet'],
> = {
  createFrom?: Resolver<ResolversTypes['CreateFrom'], ParentType, ContextType>
  cycle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mcq?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  review?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  study?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  withAI?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetDefinitionObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetDefinitionObject'] = ResolversParentTypes['SheetDefinitionObject'],
> = {
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  value?: Resolver<
    ResolversTypes['SheetDefinitionValue'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetDefinitionValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetDefinitionValue'] = ResolversParentTypes['SheetDefinitionValue'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetInformation'] = ResolversParentTypes['SheetInformation'],
> = {
  editor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetObject'] = ResolversParentTypes['SheetObject'],
> = {
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SheetObjectUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetObjectUnion'] = ResolversParentTypes['SheetObjectUnion'],
> = {
  __resolveType: TypeResolveFn<
    'SheetDefinitionObject' | 'SheetObject',
    ParentType,
    ContextType
  >
}

export type SheetResumeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SheetResume'] = ResolversParentTypes['SheetResume'],
> = {
  datas?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  editor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sheet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  drafts?: Resolver<
    Array<ResolversTypes['SavedSheet']>,
    ParentType,
    ContextType
  >
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  lastname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  premium?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  registration?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sheets?: Resolver<
    Array<ResolversTypes['GeneratedSheet']>,
    ParentType,
    ContextType
  >
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserInformationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserInformations'] = ResolversParentTypes['UserInformations'],
> = {
  acceptEmail?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  birthday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  formation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  generate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  lastname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pseudo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserList'] = ResolversParentTypes['UserList'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserSheetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSheets'] = ResolversParentTypes['UserSheets'],
> = {
  generatedSheets?: Resolver<
    Array<ResolversTypes['GeneratedSheet']>,
    ParentType,
    ContextType
  >
  savedSheets?: Resolver<
    Array<ResolversTypes['SavedSheet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  Admin?: AdminResolvers<ContextType>
  AuthUser?: AuthUserResolvers<ContextType>
  BigInt?: GraphQLScalarType
  Blog?: BlogResolvers<ContextType>
  CreateSheet?: CreateSheetResolvers<ContextType>
  DashboadType?: DashboadTypeResolvers<ContextType>
  DataInformations?: DataInformationsResolvers<ContextType>
  GeneratedSheet?: GeneratedSheetResolvers<ContextType>
  GeneratedSheetDetails?: GeneratedSheetDetailsResolvers<ContextType>
  LoginAdmin?: LoginAdminResolvers<ContextType>
  MCQHistory?: McqHistoryResolvers<ContextType>
  MCT?: MctResolvers<ContextType>
  MCTOption?: MctOptionResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  PaymentIntent?: PaymentIntentResolvers<ContextType>
  PerDate?: PerDateResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  SavedSheet?: SavedSheetResolvers<ContextType>
  Search?: SearchResolvers<ContextType>
  Sheet?: SheetResolvers<ContextType>
  SheetDefinitionObject?: SheetDefinitionObjectResolvers<ContextType>
  SheetDefinitionValue?: SheetDefinitionValueResolvers<ContextType>
  SheetInformation?: SheetInformationResolvers<ContextType>
  SheetObject?: SheetObjectResolvers<ContextType>
  SheetObjectUnion?: SheetObjectUnionResolvers<ContextType>
  SheetResume?: SheetResumeResolvers<ContextType>
  User?: UserResolvers<ContextType>
  UserInformations?: UserInformationsResolvers<ContextType>
  UserList?: UserListResolvers<ContextType>
  UserSheets?: UserSheetsResolvers<ContextType>
}
