/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation BlogAdmin($id: Int, $title: String, $value: String, $summary: String, $header: String, $state: Int) {\n    BlogAdmin(id: $id, title: $title, value: $value, summary: $summary, header: $header, state: $state)\n  }\n':
    types.BlogAdminDocument,
  '\n  mutation SendEmail($data: SendEmailInput!) {\n    SendEmail(data: $data)\n  }\n':
    types.SendEmailDocument,
  '\n  mutation LoginAdmin($username: String!, $password: String!) {\n    LoginAdmin(username: $username, password: $password) {\n      level\n      token\n    }\n  }\n':
    types.LoginAdminDocument,
  '\n  mutation SheetManagement($sheet: Int!, $type: SheetManagementType!) {\n    SheetManagement(sheet: $sheet, type: $type)\n  }\n':
    types.SheetManagementDocument,
  '\n  mutation DeleteUserMutation($user: String) {\n    DeleteUser(user: $user)\n  }\n':
    types.DeleteUserMutationDocument,
  '\n  query Blogs($limit: Int, $id: Int) {\n    Blogs(limit: $limit, id: $id) {\n      value\n      date\n      title\n      writer\n      state\n      summary\n      id\n      header\n    }\n  }\n':
    types.BlogsDocument,
  '\n  query AdminDashboard($startDate: String!, $endDate: String!) {\n    AdminDashboard(startDate: $startDate, endDate: $endDate) {\n      todaySheets\n      sheets\n      todayUsers\n      users\n      perDateSheet {\n        x\n        y\n      }\n      perDateUser {\n        x\n        y\n      }\n      drafts\n      downloads\n      views\n      mcq\n    }\n  }\n':
    types.AdminDashboardDocument,
  '\n  query GeneratedSheet($sheet: Int!) {\n    GeneratedSheet(sheet: $sheet) {\n      value\n      type\n      title\n      study\n      cycle\n      year\n      hasAccess\n      subject\n    }\n  }\n':
    types.GeneratedSheetDocument,
  '\n  query AdminSheets($page: Int, $search: String, $toValidate: Boolean) {\n    AdminSheets(page: $page, search: $search, toValidate: $toValidate) {\n      count\n      sheets {\n        id\n        title\n        cycle\n        subject\n        user\n        year\n        type\n        review\n        study\n        date\n        isValid\n        userToken\n        withAI\n        mcq\n      }\n    }\n  }\n':
    types.AdminSheetsDocument,
  '\n  query AdminUsers(\n    $firstname: String\n    $lastname: String\n    $email: String\n    $page: Int\n    $token: String\n  ) {\n    AdminUsers(\n      firstname: $firstname\n      lastname: $lastname\n      email: $email\n      page: $page\n      token: $token\n    ) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        token\n        email\n        premium\n      }\n    }\n  }\n':
    types.AdminUsersDocument,
  '\n  query AdminUser($token: String) {\n    AdminUsers(token: $token) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        birthday\n        address\n        city\n        photo\n        zip\n        country\n        phone\n        email\n        token\n        sheets {\n          id\n          title\n          date\n        }\n        drafts {\n          token\n          title\n          date\n        }\n      }\n    }\n  }\n':
    types.AdminUserDocument,
  '\n  query AdminList {\n    AdminList {\n      email\n      id\n    }\n  }\n':
    types.AdminListDocument,
}

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation BlogAdmin($id: Int, $title: String, $value: String, $summary: String, $header: String, $state: Int) {\n    BlogAdmin(id: $id, title: $title, value: $value, summary: $summary, header: $header, state: $state)\n  }\n',
): (typeof documents)['\n  mutation BlogAdmin($id: Int, $title: String, $value: String, $summary: String, $header: String, $state: Int) {\n    BlogAdmin(id: $id, title: $title, value: $value, summary: $summary, header: $header, state: $state)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SendEmail($data: SendEmailInput!) {\n    SendEmail(data: $data)\n  }\n',
): (typeof documents)['\n  mutation SendEmail($data: SendEmailInput!) {\n    SendEmail(data: $data)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LoginAdmin($username: String!, $password: String!) {\n    LoginAdmin(username: $username, password: $password) {\n      level\n      token\n    }\n  }\n',
): (typeof documents)['\n  mutation LoginAdmin($username: String!, $password: String!) {\n    LoginAdmin(username: $username, password: $password) {\n      level\n      token\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation SheetManagement($sheet: Int!, $type: SheetManagementType!) {\n    SheetManagement(sheet: $sheet, type: $type)\n  }\n',
): (typeof documents)['\n  mutation SheetManagement($sheet: Int!, $type: SheetManagementType!) {\n    SheetManagement(sheet: $sheet, type: $type)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteUserMutation($user: String) {\n    DeleteUser(user: $user)\n  }\n',
): (typeof documents)['\n  mutation DeleteUserMutation($user: String) {\n    DeleteUser(user: $user)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Blogs($limit: Int, $id: Int) {\n    Blogs(limit: $limit, id: $id) {\n      value\n      date\n      title\n      writer\n      state\n      summary\n      id\n      header\n    }\n  }\n',
): (typeof documents)['\n  query Blogs($limit: Int, $id: Int) {\n    Blogs(limit: $limit, id: $id) {\n      value\n      date\n      title\n      writer\n      state\n      summary\n      id\n      header\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminDashboard($startDate: String!, $endDate: String!) {\n    AdminDashboard(startDate: $startDate, endDate: $endDate) {\n      todaySheets\n      sheets\n      todayUsers\n      users\n      perDateSheet {\n        x\n        y\n      }\n      perDateUser {\n        x\n        y\n      }\n      drafts\n      downloads\n      views\n      mcq\n    }\n  }\n',
): (typeof documents)['\n  query AdminDashboard($startDate: String!, $endDate: String!) {\n    AdminDashboard(startDate: $startDate, endDate: $endDate) {\n      todaySheets\n      sheets\n      todayUsers\n      users\n      perDateSheet {\n        x\n        y\n      }\n      perDateUser {\n        x\n        y\n      }\n      drafts\n      downloads\n      views\n      mcq\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GeneratedSheet($sheet: Int!) {\n    GeneratedSheet(sheet: $sheet) {\n      value\n      type\n      title\n      study\n      cycle\n      year\n      hasAccess\n      subject\n    }\n  }\n',
): (typeof documents)['\n  query GeneratedSheet($sheet: Int!) {\n    GeneratedSheet(sheet: $sheet) {\n      value\n      type\n      title\n      study\n      cycle\n      year\n      hasAccess\n      subject\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminSheets($page: Int, $search: String, $toValidate: Boolean) {\n    AdminSheets(page: $page, search: $search, toValidate: $toValidate) {\n      count\n      sheets {\n        id\n        title\n        cycle\n        subject\n        user\n        year\n        type\n        review\n        study\n        date\n        isValid\n        userToken\n        withAI\n        mcq\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminSheets($page: Int, $search: String, $toValidate: Boolean) {\n    AdminSheets(page: $page, search: $search, toValidate: $toValidate) {\n      count\n      sheets {\n        id\n        title\n        cycle\n        subject\n        user\n        year\n        type\n        review\n        study\n        date\n        isValid\n        userToken\n        withAI\n        mcq\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminUsers(\n    $firstname: String\n    $lastname: String\n    $email: String\n    $page: Int\n    $token: String\n  ) {\n    AdminUsers(\n      firstname: $firstname\n      lastname: $lastname\n      email: $email\n      page: $page\n      token: $token\n    ) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        token\n        email\n        premium\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminUsers(\n    $firstname: String\n    $lastname: String\n    $email: String\n    $page: Int\n    $token: String\n  ) {\n    AdminUsers(\n      firstname: $firstname\n      lastname: $lastname\n      email: $email\n      page: $page\n      token: $token\n    ) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        token\n        email\n        premium\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminUser($token: String) {\n    AdminUsers(token: $token) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        birthday\n        address\n        city\n        photo\n        zip\n        country\n        phone\n        email\n        token\n        sheets {\n          id\n          title\n          date\n        }\n        drafts {\n          token\n          title\n          date\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminUser($token: String) {\n    AdminUsers(token: $token) {\n      count\n      users {\n        id\n        firstname\n        lastname\n        registration\n        birthday\n        address\n        city\n        photo\n        zip\n        country\n        phone\n        email\n        token\n        sheets {\n          id\n          title\n          date\n        }\n        drafts {\n          token\n          title\n          date\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query AdminList {\n    AdminList {\n      email\n      id\n    }\n  }\n',
): (typeof documents)['\n  query AdminList {\n    AdminList {\n      email\n      id\n    }\n  }\n']

export function gql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
