import { gql } from '@apollo/client'

export const DASHBOARD = gql(/* GraphQL */ `
  query AdminDashboard($startDate: String!, $endDate: String!) {
    AdminDashboard(startDate: $startDate, endDate: $endDate) {
      todaySheets
      sheets
      todayUsers
      users
      perDateSheet {
        x
        y
      }
      perDateUser {
        x
        y
      }
      drafts
      downloads
      views
      mcq
    }
  }
`)
