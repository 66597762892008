/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** BigInt */
  BigInt: { input: any; output: any }
}

export type AiSheetDatasInput = {
  cycle?: InputMaybe<Scalars['Int']['input']>
  lang: SheetLangType
  search: Scalars['String']['input']
  subject?: InputMaybe<Scalars['String']['input']>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type Admin = {
  __typename?: 'Admin'
  email: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type AuthUser = {
  __typename?: 'AuthUser'
  currency: Currency
  generated: Scalars['Int']['output']
  initials: Scalars['String']['output']
  photo?: Maybe<Scalars['String']['output']>
  premium: Scalars['Boolean']['output']
  token: Scalars['String']['output']
}

export type Blog = {
  __typename?: 'Blog'
  date: Scalars['String']['output']
  header: Scalars['String']['output']
  id: Scalars['Int']['output']
  state: Scalars['String']['output']
  summary: Scalars['String']['output']
  title: Scalars['String']['output']
  value: Scalars['String']['output']
  writer: Scalars['String']['output']
}

export enum CreateFrom {
  Mcq = 'MCQ',
  User = 'USER',
}

export type CreateSheet = {
  __typename?: 'CreateSheet'
  id?: Maybe<Scalars['Int']['output']>
  saveToken?: Maybe<Scalars['ID']['output']>
}

export enum Currency {
  Eur = 'EUR',
  Usd = 'USD',
}

export type DashboadType = {
  __typename?: 'DashboadType'
  downloads: Scalars['Int']['output']
  drafts: Scalars['Int']['output']
  mcq: Scalars['BigInt']['output']
  perDateSheet: PerDate
  perDateUser: PerDate
  sheets: Scalars['Int']['output']
  todaySheets: Scalars['Int']['output']
  todayUsers: Scalars['Int']['output']
  users: Scalars['Int']['output']
  views: Scalars['Int']['output']
}

export type DataInformations = {
  __typename?: 'DataInformations'
  definitions: Scalars['Int']['output']
  sheets: Scalars['Int']['output']
  users: Scalars['Int']['output']
}

export type GeneratedSheet = {
  __typename?: 'GeneratedSheet'
  accessibility: SheetAccessibilityType
  cycle?: Maybe<Scalars['Int']['output']>
  date: Scalars['String']['output']
  id: Scalars['Int']['output']
  isValid: Scalars['Boolean']['output']
  study?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  token?: Maybe<Scalars['String']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type GeneratedSheetDetails = {
  __typename?: 'GeneratedSheetDetails'
  cycle?: Maybe<Scalars['Int']['output']>
  hasAccess: Scalars['Boolean']['output']
  study?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type: Scalars['Int']['output']
  value: Scalars['String']['output']
  year?: Maybe<Scalars['Int']['output']>
}

export type LoginAdmin = {
  __typename?: 'LoginAdmin'
  level: Scalars['Int']['output']
  token: Scalars['String']['output']
}

export type McqHistory = {
  __typename?: 'MCQHistory'
  correct: Scalars['Int']['output']
  createFrom: CreateFrom
  date: Scalars['String']['output']
  sheetId: Scalars['Int']['output']
  sheetTitle: Scalars['String']['output']
  total: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type Mct = {
  __typename?: 'MCT'
  answer: Scalars['Int']['output']
  options: Array<MctOption>
  question: Scalars['String']['output']
  sheet: Scalars['Int']['output']
}

export type MctInput = {
  fromText?: InputMaybe<Scalars['String']['input']>
  questions: Array<MctQuestionInput>
  sheet?: InputMaybe<Scalars['Int']['input']>
}

export type MctOption = {
  __typename?: 'MCTOption'
  index: Scalars['Int']['output']
  text: Scalars['String']['output']
}

export type MctQuestionInput = {
  answer: Scalars['Int']['input']
  options: Array<MctQuestionOptionInput>
  question: Scalars['String']['input']
}

export type MctQuestionOptionInput = {
  index?: InputMaybe<Scalars['Int']['input']>
  text: Scalars['String']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  AIDefinition: Scalars['String']['output']
  AISheet: Scalars['String']['output']
  Advise: Scalars['Boolean']['output']
  BlogAdmin: Scalars['String']['output']
  Checkout: Scalars['String']['output']
  CreateMCT: Array<Mct>
  CreateOrUpdateSheet: CreateSheet
  Delete: Scalars['Boolean']['output']
  DeleteUser: Scalars['Boolean']['output']
  Login: AuthUser
  LoginAdmin?: Maybe<LoginAdmin>
  PaymentIntent: PaymentIntent
  Register: AuthUser
  ResetPassword: Scalars['Boolean']['output']
  SaveMCQ: Scalars['Boolean']['output']
  SendEmail: Scalars['Boolean']['output']
  SheetManagement: Scalars['Boolean']['output']
  Subscription: Scalars['String']['output']
  UpdateAccessibility: Scalars['Boolean']['output']
  UpdatePassword: Scalars['Boolean']['output']
  UpdateUserInformations: Scalars['Boolean']['output']
  Upload: Scalars['String']['output']
  ValidateEmail: Scalars['Boolean']['output']
}

export type MutationAiDefinitionArgs = {
  lang: SheetLangType
  search: Scalars['String']['input']
}

export type MutationAiSheetArgs = {
  datas: AiSheetDatasInput
}

export type MutationAdviseArgs = {
  content: Scalars['String']['input']
  rating: Scalars['Int']['input']
}

export type MutationBlogAdminArgs = {
  header?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['Int']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type MutationCheckoutArgs = {
  id: Scalars['ID']['input']
}

export type MutationCreateMctArgs = {
  mct: MctInput
}

export type MutationCreateOrUpdateSheetArgs = {
  data: SheetDataInput
}

export type MutationDeleteArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>
  sheet: Scalars['Int']['input']
}

export type MutationDeleteUserArgs = {
  user?: InputMaybe<Scalars['String']['input']>
}

export type MutationLoginArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationLoginAdminArgs = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type MutationPaymentIntentArgs = {
  currency?: InputMaybe<Currency>
}

export type MutationRegisterArgs = {
  data: RegisterInput
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']['input']
}

export type MutationSaveMcqArgs = {
  mcq: SaveMcqInput
}

export type MutationSendEmailArgs = {
  data: SendEmailInput
}

export type MutationSheetManagementArgs = {
  sheet: Scalars['Int']['input']
  type: SheetManagementType
}

export type MutationSubscriptionArgs = {
  price?: InputMaybe<SubscriptionType>
}

export type MutationUpdateAccessibilityArgs = {
  accessibility: SheetAccessibilityType
  sheet: Scalars['Int']['input']
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationUpdateUserInformationsArgs = {
  data: UserInformationsInput
}

export type MutationUploadArgs = {
  data: Scalars['String']['input']
  type: UploadType
}

export type MutationValidateEmailArgs = {
  code: Scalars['String']['input']
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent'
  id: Scalars['String']['output']
  secret: Scalars['String']['output']
}

export type PerDate = {
  __typename?: 'PerDate'
  x: Array<Scalars['String']['output']>
  y: Array<Scalars['Int']['output']>
}

export type Query = {
  __typename?: 'Query'
  AICorrection: Array<Scalars['String']['output']>
  AdminDashboard: DashboadType
  AdminList: Array<Admin>
  AdminSheets: Search
  AdminUsers: UserList
  Blogs: Array<Blog>
  GeneratedSheet: GeneratedSheetDetails
  GlobalInformations: DataInformations
  MCQHistory: Array<McqHistory>
  Mct: Array<Mct>
  PortalSession: Scalars['String']['output']
  PremiumStatus: Scalars['Boolean']['output']
  Resume: SheetResume
  Search: Search
  SheetInformation: SheetInformation
  Sheets: UserSheets
  UserInformations: UserInformations
}

export type QueryAiCorrectionArgs = {
  input: Array<Scalars['String']['input']>
  lang: SheetLangType
}

export type QueryAdminDashboardArgs = {
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type QueryAdminSheetsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  toValidate?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAdminUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  token?: InputMaybe<Scalars['String']['input']>
}

export type QueryBlogsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGeneratedSheetArgs = {
  sheet: Scalars['Int']['input']
}

export type QueryMctArgs = {
  sheet: Scalars['Int']['input']
}

export type QueryResumeArgs = {
  sheet: Scalars['String']['input']
}

export type QuerySearchArgs = {
  cycle?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
}

export type QuerySheetInformationArgs = {
  token: Scalars['ID']['input']
}

export type RegisterInput = {
  acceptEmail?: InputMaybe<Scalars['Boolean']['input']>
  email: Scalars['String']['input']
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  nationality: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type SaveMcqInput = {
  correct: Scalars['Int']['input']
  sheetId: Scalars['Int']['input']
  total: Scalars['Int']['input']
  value: Scalars['String']['input']
}

export type SavedSheet = {
  __typename?: 'SavedSheet'
  date: Scalars['String']['output']
  id: Scalars['Int']['output']
  title: Scalars['String']['output']
  token: Scalars['String']['output']
}

export type Search = {
  __typename?: 'Search'
  count: Scalars['Int']['output']
  sheets: Array<Sheet>
}

export type SendEmailInput = {
  all: Scalars['Boolean']['input']
  content: Scalars['String']['input']
  emails: Array<Scalars['String']['input']>
  subject: Scalars['String']['input']
}

export type Sheet = {
  __typename?: 'Sheet'
  createFrom: CreateFrom
  cycle?: Maybe<Scalars['Int']['output']>
  date: Scalars['String']['output']
  id: Scalars['Int']['output']
  isValid: Scalars['Boolean']['output']
  mcq: Scalars['BigInt']['output']
  review: Scalars['Float']['output']
  study?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type?: Maybe<Scalars['Int']['output']>
  user: Scalars['String']['output']
  userToken: Scalars['String']['output']
  withAI: Scalars['Boolean']['output']
  year?: Maybe<Scalars['Int']['output']>
}

export enum SheetAccessibilityType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum SheetActionType {
  Create = 'CREATE',
  Save = 'SAVE',
}

export type SheetDataInput = {
  action: SheetActionType
  datas: SheetDatasInput
  editor?: InputMaybe<Scalars['String']['input']>
  file?: InputMaybe<Scalars['String']['input']>
  img: Scalars['String']['input']
  lang?: InputMaybe<SheetLangType>
  payment?: InputMaybe<Scalars['ID']['input']>
  sheet?: InputMaybe<Scalars['ID']['input']>
  withAi?: InputMaybe<Scalars['Boolean']['input']>
}

export type SheetDatasInput = {
  accessibility: SheetAccessibilityType
  cycle?: InputMaybe<Scalars['Int']['input']>
  font?: InputMaybe<Scalars['String']['input']>
  fontSize?: InputMaybe<Scalars['Float']['input']>
  primaryColor?: InputMaybe<Scalars['String']['input']>
  speciality?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  study?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
  template?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  withDefintions?: InputMaybe<Scalars['Boolean']['input']>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type SheetDefinitionObject = {
  __typename?: 'SheetDefinitionObject'
  type: Scalars['Int']['output']
  value: SheetDefinitionValue
}

export type SheetDefinitionValue = {
  __typename?: 'SheetDefinitionValue'
  content?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type SheetInformation = {
  __typename?: 'SheetInformation'
  editor: Scalars['String']['output']
  token: Scalars['ID']['output']
}

export enum SheetLangType {
  En = 'EN',
  Fr = 'FR',
}

export enum SheetManagementType {
  Delete = 'DELETE',
  ToogleState = 'TOOGLE_STATE',
}

export type SheetObject = {
  __typename?: 'SheetObject'
  type: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type SheetObjectUnion = SheetDefinitionObject | SheetObject

export type SheetResume = {
  __typename?: 'SheetResume'
  datas: Scalars['String']['output']
  editor: Scalars['String']['output']
  sheet?: Maybe<Scalars['Int']['output']>
}

export enum SheetType {
  Generated = 'GENERATED',
  Pdf = 'PDF',
}

export enum SubscriptionType {
  Mensual = 'MENSUAL',
  Trimestrial = 'TRIMESTRIAL',
}

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input']
  token: Scalars['String']['input']
  type: UpdatePasswordType
}

export enum UpdatePasswordType {
  Reset = 'RESET',
  Update = 'UPDATE',
}

export enum UploadType {
  Upload = 'UPLOAD',
  User = 'USER',
}

export type User = {
  __typename?: 'User'
  address?: Maybe<Scalars['String']['output']>
  birthday?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  drafts: Array<SavedSheet>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  lastname?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  premium: Scalars['Boolean']['output']
  registration: Scalars['String']['output']
  sheets: Array<GeneratedSheet>
  token: Scalars['String']['output']
  zip?: Maybe<Scalars['String']['output']>
}

export type UserInformations = {
  __typename?: 'UserInformations'
  acceptEmail?: Maybe<Scalars['Boolean']['output']>
  address?: Maybe<Scalars['String']['output']>
  birthday?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  formation?: Maybe<Scalars['String']['output']>
  generate?: Maybe<Scalars['Int']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  photo?: Maybe<Scalars['String']['output']>
  pseudo?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type UserInformationsInput = {
  accept_email?: InputMaybe<Scalars['Boolean']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  birthday?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  formation?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['String']['input']>
  pseudo?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type UserList = {
  __typename?: 'UserList'
  count: Scalars['Int']['output']
  users: Array<User>
}

export type UserSheets = {
  __typename?: 'UserSheets'
  generatedSheets: Array<GeneratedSheet>
  savedSheets: Array<SavedSheet>
}

export type BlogAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['Int']['input']>
}>

export type BlogAdminMutation = { __typename?: 'Mutation'; BlogAdmin: string }

export type SendEmailMutationVariables = Exact<{
  data: SendEmailInput
}>

export type SendEmailMutation = { __typename?: 'Mutation'; SendEmail: boolean }

export type LoginAdminMutationVariables = Exact<{
  username: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginAdminMutation = {
  __typename?: 'Mutation'
  LoginAdmin?: {
    __typename?: 'LoginAdmin'
    level: number
    token: string
  } | null
}

export type SheetManagementMutationVariables = Exact<{
  sheet: Scalars['Int']['input']
  type: SheetManagementType
}>

export type SheetManagementMutation = {
  __typename?: 'Mutation'
  SheetManagement: boolean
}

export type DeleteUserMutationMutationVariables = Exact<{
  user?: InputMaybe<Scalars['String']['input']>
}>

export type DeleteUserMutationMutation = {
  __typename?: 'Mutation'
  DeleteUser: boolean
}

export type BlogsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type BlogsQuery = {
  __typename?: 'Query'
  Blogs: Array<{
    __typename?: 'Blog'
    value: string
    date: string
    title: string
    writer: string
    state: string
    summary: string
    id: number
    header: string
  }>
}

export type AdminDashboardQueryVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type AdminDashboardQuery = {
  __typename?: 'Query'
  AdminDashboard: {
    __typename?: 'DashboadType'
    todaySheets: number
    sheets: number
    todayUsers: number
    users: number
    drafts: number
    downloads: number
    views: number
    mcq: any
    perDateSheet: { __typename?: 'PerDate'; x: Array<string>; y: Array<number> }
    perDateUser: { __typename?: 'PerDate'; x: Array<string>; y: Array<number> }
  }
}

export type GeneratedSheetQueryVariables = Exact<{
  sheet: Scalars['Int']['input']
}>

export type GeneratedSheetQuery = {
  __typename?: 'Query'
  GeneratedSheet: {
    __typename?: 'GeneratedSheetDetails'
    value: string
    type: number
    title: string
    study?: string | null
    cycle?: number | null
    year?: number | null
    hasAccess: boolean
    subject?: string | null
  }
}

export type AdminSheetsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  toValidate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AdminSheetsQuery = {
  __typename?: 'Query'
  AdminSheets: {
    __typename?: 'Search'
    count: number
    sheets: Array<{
      __typename?: 'Sheet'
      id: number
      title: string
      cycle?: number | null
      subject?: string | null
      user: string
      year?: number | null
      type?: number | null
      review: number
      study?: string | null
      date: string
      isValid: boolean
      userToken: string
      withAI: boolean
      mcq: any
    }>
  }
}

export type AdminUsersQueryVariables = Exact<{
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  token?: InputMaybe<Scalars['String']['input']>
}>

export type AdminUsersQuery = {
  __typename?: 'Query'
  AdminUsers: {
    __typename?: 'UserList'
    count: number
    users: Array<{
      __typename?: 'User'
      id: number
      firstname?: string | null
      lastname?: string | null
      registration: string
      token: string
      email?: string | null
      premium: boolean
    }>
  }
}

export type AdminUserQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>
}>

export type AdminUserQuery = {
  __typename?: 'Query'
  AdminUsers: {
    __typename?: 'UserList'
    count: number
    users: Array<{
      __typename?: 'User'
      id: number
      firstname?: string | null
      lastname?: string | null
      registration: string
      birthday?: string | null
      address?: string | null
      city?: string | null
      photo?: string | null
      zip?: string | null
      country?: string | null
      phone?: string | null
      email?: string | null
      token: string
      sheets: Array<{
        __typename?: 'GeneratedSheet'
        id: number
        title: string
        date: string
      }>
      drafts: Array<{
        __typename?: 'SavedSheet'
        token: string
        title: string
        date: string
      }>
    }>
  }
}

export type AdminListQueryVariables = Exact<{ [key: string]: never }>

export type AdminListQuery = {
  __typename?: 'Query'
  AdminList: Array<{ __typename?: 'Admin'; email: string; id: number }>
}

export const BlogAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlogAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'summary' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'header' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'BlogAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'title' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'summary' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'summary' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'header' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'header' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlogAdminMutation, BlogAdminMutationVariables>
export const SendEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SendEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'SendEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailMutation, SendEmailMutationVariables>
export const LoginAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LoginAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'username' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'username' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginAdminMutation, LoginAdminMutationVariables>
export const SheetManagementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SheetManagement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sheet' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SheetManagementType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'SheetManagement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sheet' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sheet' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SheetManagementMutation,
  SheetManagementMutationVariables
>
export const DeleteUserMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DeleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserMutationMutation,
  DeleteUserMutationMutationVariables
>
export const BlogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Blogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Blogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'writer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'header' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlogsQuery, BlogsQueryVariables>
export const AdminDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AdminDashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'todaySheets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sheets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'todayUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perDateSheet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perDateUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'drafts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloads' } },
                { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mcq' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminDashboardQuery, AdminDashboardQueryVariables>
export const GeneratedSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeneratedSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sheet' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GeneratedSheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sheet' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sheet' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'study' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneratedSheetQuery, GeneratedSheetQueryVariables>
export const AdminSheetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminSheets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toValidate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AdminSheets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toValidate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'toValidate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sheets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subject' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'review' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'study' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isValid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userToken' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'withAI' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'mcq' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminSheetsQuery, AdminSheetsQueryVariables>
export const AdminUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstname' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastname' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AdminUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstname' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastname' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'premium' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUsersQuery, AdminUsersQueryVariables>
export const AdminUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AdminUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registration' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sheets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'drafts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'token' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'date' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUserQuery, AdminUserQueryVariables>
export const AdminListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AdminList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListQuery, AdminListQueryVariables>
