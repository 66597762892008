import React, { useEffect, useState } from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Autocomplete } from '@mui/material'
import { DASHBOARD } from 'helpers/graphql/query/dashboard.graphql'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Line } from 'react-chartjs-2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'
import { addMonths } from 'date-fns'
import { formatDate } from '../../Service/Functions'
import frLocale from 'date-fns/locale/fr'
import { useQuery } from '@apollo/client'

const Dashboard = () => {
  const [dateFilter, setDateFilter] = useState<(Date | null)[]>([
    addMonths(new Date(), -3),
    new Date(),
  ])
  const [cycleFilter, setCycleFilter] = useState<null | number>(null)
  const [datas, setDatas] = useState(
    undefined as undefined | Query['AdminDashboard'],
  )

  const { data, refetch } = useQuery(DASHBOARD, {
    variables: { startDate: dateFilter[0], endDate: dateFilter[1] },
  })

  useEffect(() => {
    refetch({
      startDate: dateFilter[0],
      endDate: dateFilter[1],
    })
  }, [dateFilter])

  useEffect(() => {
    if (data?.AdminDashboard) {
      setDatas(data.AdminDashboard)
    }
  }, [data?.AdminDashboard])

  // const chartRef = useRef<any>()
  // const backgroundColor = [
  //   'rgba(255,117,99,0.2)',
  //   'rgba(114,154,56,0.2)',
  //   'rgba(235,111,54,0.2)',
  //   'rgba(86,255,94,0.2)',
  //   'rgba(255,173,86,0.2)',
  //   'rgba(86,255,216,0.2)',
  //   'rgba(86,207,255,0.2)',
  //   'rgba(192,74,255,0.2)',
  //   'rgba(86,148,255,0.2)',
  //   'rgba(255,86,193,0.2)',
  //   'rgba(103,86,255,0.2)',
  //   'rgba(255,86,86,0.2)',
  // ]
  // let borderColor = [
  //   'rgba(255,117,99,1)',
  //   'rgba(114,154,56,1)',
  //   'rgba(235,111,54,1)',
  //   'rgba(86,255,94,1)',
  //   'rgba(255,173,86,1)',
  //   'rgba(86,255,216,1)',
  //   'rgba(86,207,255,1)',
  //   'rgba(192,74,255,1)',
  //   'rgba(86,148,255,1)',
  //   'rgba(255,86,193,1)',
  //   'rgba(103,86,255,1)',
  //   'rgba(255,86,86,1)',
  // ]
  // let yearsName = [
  //   ['Sixième', 'Cinquième', 'Quatrième', 'Troisième'],
  //   ['Seconde', 'Première', 'Terminale'],
  //   ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  // ]
  const cycle = [
    {
      id: 0,
      name: 'Collège',
    },
    {
      id: 1,
      name: 'Lycée',
    },
    {
      id: 2,
      name: 'Post-Bac',
    },
  ]

  const dataSheet = {
    labels: datas?.perDateSheet.x || [],
    datasets: [
      {
        label: 'Génération / Jour',
        data: datas?.perDateSheet.y || [],
        radius: 0,
        tension: 0.1,
        borderWidth: 1.4,
        backgroundColor: 'rgba(255,99,132,0.2)',
        fill: true,
        borderColor: 'rgb(255,99,132)',
      },
    ],
  }

  const dataUser = {
    labels: datas?.perDateUser.x || [],
    datasets: [
      {
        label: 'Inscription / Jour',
        data: datas?.perDateUser.y || [],
        radius: 0,
        tension: 0.1,
        borderWidth: 1.4,
        backgroundColor: 'rgba(99,161,255, 0.2)',
        borderColor: 'rgb(99,161,255)',
      },
    ],
  }

  // const cycleData = {
  //   labels: ['Collège', 'Lycée', 'Post-Bac'],
  //   datasets: [
  //     {
  //       label: 'Nombre de fiches',
  //       data: [
  //         datas.details[0].count,
  //         datas.details[1].count,
  //         datas.details[2].count,
  //       ],
  //       backgroundColor: backgroundColor,
  //       borderColor: borderColor,
  //       borderWidth: 1,
  //     },
  //   ],
  // }

  // let years = {
  //   labels: [] as string[],
  //   datasets: [] as any[],
  // }
  // if (cycleFilter !== null) {
  //   years = {
  //     labels: yearsName[cycleFilter],
  //     datasets: [
  //       {
  //         label: 'Nombre de fiches',
  //         data: Object.values(datas.details[cycleFilter]['years']),
  //         backgroundColor: backgroundColor,
  //         borderColor: borderColor,
  //         borderWidth: 1,
  //       },
  //     ],
  //   }
  // }

  return (
    <>
      <Typography variant={'h1'} align={'center'} className={'py-3'}>
        Dashboard
      </Typography>
      <div className="row">
        <div className="col-8">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <div className="row">
              <div className={'col-6 px-2 datepicker'}>
                <DatePicker
                  mask={'__/__/____'}
                  label={'Début'}
                  value={dateFilter[0]}
                  onChange={newValue => {
                    let v = [...dateFilter]
                    v[0] = newValue
                    setDateFilter(v)
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </div>
              <div className={'col-6 px-2 datepicker'}>
                <DatePicker
                  mask={'__/__/____'}
                  label={'Fin'}
                  value={dateFilter[1]}
                  onChange={newValue => {
                    let v = [...dateFilter]
                    v[1] = newValue
                    setDateFilter(v)
                  }}
                  renderInput={params => <TextField {...params} />}
                />
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div className="col-4">
          <Autocomplete
            disablePortal
            options={cycle}
            value={cycleFilter !== null ? cycle[cycleFilter] : null}
            onChange={(e, v) => {
              setCycleFilter(v !== null ? v.id : null)
            }}
            openOnFocus={true}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Cycle" />}
          />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 text-center py-2 px-1 ">
          <Typography variant={'h1'} component={'p'}>
            {new Intl.NumberFormat('fr-FR').format(datas?.views || 0)} fiches
            consultées
          </Typography>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 col-md-6 text-center py-2 px-1 order-0 order-md-0">
          <Typography variant={'h1'} component={'p'}>
            +{datas?.todaySheets || 0}
          </Typography>
          <Typography variant={'h5'} component={'p'}>
            Nouvelle{(datas?.todaySheets || 0) > 1 ? 's' : ''} fiche
            {(datas?.todaySheets || 0) > 1 ? 's' : ''} aujourd'hui
          </Typography>
          <div className={'text-center mt-5'}>
            <Typography variant={'h1'} component={'p'}>
              {new Intl.NumberFormat('fr-FR').format(datas?.sheets || 0)}
            </Typography>
            <Typography variant={'h5'} component={'p'}>
              Fiches
            </Typography>
          </div>
          <div className={'text-center mt-5'}>
            <Typography variant={'h1'} component={'p'}>
              +{datas?.drafts || 0}
            </Typography>
            <Typography variant={'h5'} component={'p'}>
              Nouveau{(datas?.drafts || 0) > 1 ? 'x' : ''} brouillon
              {(datas?.drafts || 0) > 1 ? 's' : ''} aujourd'hui
            </Typography>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center py-2 px-1 order-2 order-md-1">
          <Typography variant={'h1'} component={'p'}>
            +{datas?.todayUsers || 0}
          </Typography>
          <Typography variant={'h5'} component={'p'}>
            Nouveau{(datas?.todayUsers || 0) > 1 ? 'x' : ''} utilisateur
            {(datas?.todayUsers || 0) > 1 ? 's' : ''} aujourd'hui
          </Typography>
          <div className={'text-center mt-5'}>
            <Typography variant={'h1'} component={'p'}>
              {new Intl.NumberFormat('fr-FR').format(datas?.users || 0)}
            </Typography>
            <Typography variant={'h5'} component={'p'}>
              Utilisateurs
            </Typography>
          </div>
          <div className={'text-center mt-5'}>
            <Typography variant={'h1'} component={'p'}>
              {new Intl.NumberFormat('fr-FR').format(datas?.mcq || 0)}
            </Typography>
            <Typography variant={'h5'} component={'p'}>
              QCM générés
            </Typography>
          </div>
        </div>
        <div className="col-12 col-xl-6 py-2 px-2 order-1 order-md-2">
          <Line data={dataSheet} />
        </div>
        <div className="col-12 col-xl-6 py-2 px-2 order-3 order-md-3">
          <Line data={dataUser} />
        </div>
        {/* <div className="col-12 col-xl-6 py-2 px-2 order-3 order-md-3">
          <Bar
            ref={chartRef}
            data={cycleData}
            onClick={event => {
              let element = getElementAtEvent(chartRef?.current, event)
              if (element.length > 0) {
                setCycleFilter(element[0]['index'])
              }
            }}
          />
        </div> */}
        {/* {cycleFilter !== null && (
          <div className="col-12 col-xl-6 py-2 px-2 order-3 order-md-3">
            <Bar data={years} />
          </div>
        )} */}
      </div>
    </>
  )
}

export default Dashboard
