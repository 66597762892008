import Cookies from 'js-cookie'

const initialState = {
    loggedIn: false,
    user: null,
    token: null,
}

function auth(state = initialState, action) {
    switch (action.type) {
        case 'CONNECT':
            // Cookies.set('token', action.value['token'])
            return {
                token: action.value['token'],
                level: action.value['level'],
                loggedIn: true,
            };
        case 'DISCONNECT':
            // Cookies.delete('token')
            return {
                loggedIn: false,
                token: null,
                level: null,
            };
        default:
            return state
    }
}

export default auth